import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/spkKontrakApproval'

export default {
  get (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  signing (params) {
    return httpClient.post(`${endpoint}/signing`, params)
  }
}
